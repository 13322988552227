import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    Switch,
    Route,
    useRouteMatch
} from 'react-router-dom';

import Budget from './Budget';
import Theme from '../utils/Theme';

const useStyles = makeStyles(theme => ({
    pageContainer: {
        height: '100%',
    }
}));


export default () => {
    const match = useRouteMatch();
    const styles = useStyles();

    return (
        <Theme>
            <Container maxWidth="sm" className={styles.pageContainer} disableGutters={true}>
                <Switch>
                    <Route path={ `${match.path}/:periodId` }>
                        <Budget />
                    </Route>
                    <Route path={match.path}>
                        <Budget />
                    </Route>
                </Switch>
            </Container>
        </Theme>
    )
};
