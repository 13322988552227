import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import EntryService from '../../../services/EntryService';

export default props => {
    const now = moment();
    const minDate = props.period.startDate > now ? props.period.startDate : now;
    const emptyEntry = {
        public_id: '',
        name: '',
        planned_date: minDate.format('YYYY-MM-DD'),
        incoming: false,
        amount: 0
    };
    const [ edit, setEdit ] = useState(false);
    const [ entry, setEntry ] = useState(emptyEntry);
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = e => {
        e.preventDefault();
        submitEntry();
    };

    const submitEntry = () => {
        let preppedEntry = {...entry};
        if (isNaN(preppedEntry.amount)) {
            enqueueSnackbar(`Amount is not a number: ${preppedEntry.amount}`, {variant: 'error'});
            return;
        }
        preppedEntry.amount = parseFloat(preppedEntry.amount);
        if (edit) {
            EntryService.editEntry(preppedEntry)
                .then(resp => {
                    if (resp.success) {
                        props.reloadEntries();
                        props.handleClose();
                    } else {
                        enqueueSnackbar(resp.payload.msg, {variant: 'error'});
                    }
                });
        }
        else {
            delete preppedEntry.public_id;
            EntryService.createEntry(preppedEntry)
                .then(resp => {
                    if (resp.success) {
                        props.reloadEntries();
                        props.handleClose();
                    } else {
                        enqueueSnackbar(resp.payload.msg, {variant: 'error'});
                    }
                });
        }
    };

    const handleChange = e => {
        e.preventDefault();
        const attr = e.target.dataset.attribute;
        let val = e.target.value;
        if (e.target.type ==='checkbox') {
            val = e.target.checked;
        }
        let updatedEntry = {...entry};
        updatedEntry[attr] = val;
        setEntry(updatedEntry);
    };

    useEffect(() => {
        if (props.entry) {
            setEdit(true);
            let e = {};
            Object.keys(emptyEntry).forEach(key => {
                e[key] = props.entry[key];
            });
            setEntry(e);
        } else {
            setEntry(emptyEntry);
        }
    }, [ props.open ]);

    return (
        <div>
            <Dialog onClose={props.handleClose} open={props.open}>
                <DialogTitle>{ edit ? entry.name : 'New Entry' }</DialogTitle>
                <DialogContent>
                    <form id='entryForm' onChange={handleChange} onSubmit={handleSubmit}>
                        <TextField
                            autoFocus
                            margin='dense'
                            label='Name'
                            type='text'
                            value={entry.name}
                            inputProps={{'data-attribute': 'name'}}
                            fullWidth
                        />
                        <TextField
                            margin='dense'
                            label='Amount'
                            type='tel'
                            value={entry.amount}
                            inputProps={{'data-attribute': 'amount'}}
                            fullWidth
                        />
                        <TextField
                            margin='dense'
                            id=''
                            type='date'
                            label='Date'
                            value={entry.planned_date}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{'data-attribute': 'planned_date'}}
                            data-attribute='planned_date'
                            fullWidth
                        />
                        <FormControlLabel
                            label="Incoming"
                            control={<Checkbox color="primary" checked={entry.incoming} inputProps={{ 'data-attribute': 'incoming' }} />}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' type='submit' form='entryForm'>
                        {edit ? "Update" : "Create" }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
