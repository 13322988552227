import BaseAPIService from './BaseAPIService';

class TransactionService extends BaseAPIService{
    static async createTransaction(transaction) {
        const resp = await this.httpRequest('transactions/', {payload: transaction});
        return Promise.resolve(resp);
    }

    static async deleteTransaction(public_id) {
        const data = await this.httpRequest(`transactions/${public_id}/`, {method: 'DELETE'});
        return Promise.resolve(data);
    }

    static async editTransaction(transaction) {
        const data = await this.httpRequest(`transactions/${transaction.public_id}/`, {method: `PUT`, payload: transaction});
        return Promise.resolve(data);
    }
}

export default TransactionService;
