import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(theme => ({
    transactionBox: {
        padding: '2px',
        fontSize: '0.9em'
    },
    transactionCell: {
        flex: 4
    },
    transactionOut: {
        color: 'red'
    },
    transactionIn: {
        color: 'green'
    },
    currency: {
        fontSize: '0.6em'
    },
    editButton: {
        alignSelf: 'flex-end'
    }
}));

export default props => {
    const classes = useStyle();

    const editTransaction = () => {
        props.editTransaction(props.transaction);
    };

    return (
        <Box display='flex' flexDirection='row' className={classes.transactionBox} justifyContent='space-between'>
            <div className={ classes.transactionCell }>{ props.transaction.date }</div>
            <div className={ classes.transactionCell }><span className={ classes.currency }>SEK</span>{ props.transaction.amount }</div>
            <div className={ classes.transactionCell }>{ props.transaction.description }</div>
            <IconButton size='small' onClick={ editTransaction } className={ classes.editButton }><EditIcon /></IconButton>
        </Box>
    );
};
