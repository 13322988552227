import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import PeriodHead from './PeriodHead/PeriodHead';
import EntryList from './EntryList/EntryList';

const useStyles = makeStyles(theme => ({
    budgetContainer: {
        height: '100%',
    }
}));

export default (props) => {
    let { periodId } = useParams();
    const styles = useStyles();

    return (
        <Box
            className={ styles.budgetContainer }
            display='flex'
            flexDirection='column'
            height='100%'
        >
            <PeriodHead periodId={ periodId }
                        position="static" />
            <EntryList periodId={ periodId } />
        </Box>
    )
};
