import { config } from '../utils/constants';
import UserService from './UserService';

class BaseAPIService {
    static async httpRequest(uri, extraOpts={}) {
        let {auth, payload, method} = extraOpts;
        const url = `${config.API_URL}/${uri}`;
        let opts = {};
        let headers = {};

        headers['Content-Type'] = 'application/json';


        if (auth === undefined) {
            const accessToken = await UserService.getAccessToken();
            headers.Authorization = `Bearer ${accessToken}`;
            if (accessToken === undefined) {
                return Promise.resolve({
                    success: false,
                    error: 'Not logged in',
                    payload: {
                        msg: 'Not logged in'
                    },
                    unauthorized: true
                });
            }
        } else if (auth !== false) {
            headers.Authorization = `Bearer ${auth}`;
        }

        opts.headers = headers;

        if (payload !== undefined) {
            opts.body = JSON.stringify(payload);
        }

        if (method === undefined) {
            if (opts.hasOwnProperty('body')) {
                method = 'POST';
            }
            else {
                method = 'GET';
            }
        }
        opts.method = method;

        let response = await fetch(url, opts);
        const contentType = response.headers.get('content-type')
        if (! contentType || ! contentType.includes('application/json')) {
            return Promise.resolve({
                success: false,
                error: response.message,
            });
        }

        let data = await response.json();
        if (! response.ok) {
            return Promise.resolve({
                success: false,
                error: data.msg,
                payload: data
            });
        }
        return Promise.resolve({
            success: true,
            payload: data
        });
    }
}

export default BaseAPIService;
