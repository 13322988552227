import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './ProgressBar.scss';

const styles = makeStyles({
    progressBar: props => ({
        background: `linear-gradient(90deg, ${props.foregroundColor || "#FFFFFF"} ${props.progress}%, ${props.backgroundColor || "#FFFFFF"} ${props.progress}%)`
    })
});

const ProgressBar = props => {
    const classes = styles(props);
    return (
        <div className={ classes.progressBar }>
            { props.children }
        </div>
    );
}

export default ProgressBar;
