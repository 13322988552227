import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import './PeriodHead.scss';
import ProgressBar from '../../utils/ProgressBar';
import PeriodService from '../../services/PeriodService';

const summarizePeriod  = (a, b) => {
    if (b.incoming) {
        a.income += b.amount;
    }
    else {
        a.planned += b.amount;
        a.current += b.current_amount;
    }
    return a;
}

const PeriodHead = props => {
    const [period, setPeriod] = useState({
        id: null,
        startDate: moment(),
        endDate: moment(),
        name: null,
        length: null,
        progress: null,
        progressPercent: null
    });
    const { periodId } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [periodSummary, setPeriodSummary] = useState({
        "current": 0,
        "planned": 0,
        "income": 0
    });

    useEffect(() => {
        const updatePeriodSummary = (id_) => {
            PeriodService.getPeriodEntries(id_)
                .then((resp) => {
                    if (resp.success) {
                        const s = resp.payload.data.reduce(summarizePeriod, {current: 0, planned: 0, income: 0});
                        setPeriodSummary(s);
                    } else {
                        enqueueSnackbar(resp.error, {variant: 'error'});
                    }
                });
        };

        if ( period.id === periodId ) {
            console.log('period already correct');
        }
        else if ( periodId === undefined ) {
            PeriodService.getCurrentPeriod()
                .then(p => {
                    if (p.payload.id !== period.id) {
                        setPeriod(p.payload);
                    }
                    updatePeriodSummary(p.payload.id);
                });
        }
        else {
            PeriodService.getPeriod(periodId)
                .then(p => {
                    console.log(p);
                    setPeriod(p.payload);
                    updatePeriodSummary(p.payload.id);
                });
        }
    }, [periodId, period, enqueueSnackbar]);

    return (
        <div className="period-head">
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                    Prev
                </Grid>
                <Grid item className="period-name">
                    { period.name }
                </Grid>
                <Grid item>
                    Next
                </Grid>
            </Grid>
            <ProgressBar progress={period.progressPercent} backgroundColor="#FFFFFF" foregroundColor="#00aa00">
                <Grid container direction="row" justify="space-between" alignItems="center" className="period-progress">
                    <Grid item>
                        { period.startDate.format('DD/MM') }
                    </Grid>
                    <Grid item>
                        Day { period.progress }/{ period.length }
                    </Grid>
                    <Grid item>
                        { period.endDate.format('DD/MM') }
                    </Grid>
                </Grid>
            </ProgressBar>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item className="period-summary-item">
                    IN <br />
                    { periodSummary.income.toFixed(2) }
                </Grid>
                <Grid item className="period-summary-item">
                    PLANNED <br />
                    { (periodSummary.income - periodSummary.planned).toFixed(2) }
                </Grid>
                <Grid item className="period-summary-item">
                    CURRENT <br />
                    { (periodSummary.income - periodSummary.current).toFixed(2) }
                </Grid>
            </Grid>
        </div>
    )
}

export default PeriodHead;
