import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import Box from '@material-ui/core/Box';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import TransactionService from '../../../../services/TransactionService';

export default props => {
    const now = moment();
    const defaultDate = moment(props.entry.planned_date, "YYYY-MM-DD") > now ? now.format("YYYY-MM-DD") : props.entry.planned_date;
    const emptyTransaction = {
        public_id: '',
        description: '',
        amount: props.entry.amount - props.entry.current_amount,
        date: defaultDate,
        entry_public_id: props.entry.public_id
    };
    const [ edit, setEdit ] = useState(false);
    const [ transaction, setTransaction ] = useState(emptyTransaction);
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = e => {
        e.preventDefault();
        submitTransaction();
    };

    const submitTransaction = () => {
        let preppedTransaction = {...transaction};
        if (isNaN(preppedTransaction.amount)) {
            enqueueSnackbar(`Amount is not a number: ${preppedTransaction.amount}`, {variant: 'error'});
            return;
        }
        preppedTransaction.amount = parseFloat(preppedTransaction.amount);
        if (edit) {
            TransactionService.editTransaction(preppedTransaction)
                .then(resp => {
                    if (resp.success) {
                        props.reloadEntry();
                        props.handleClose();
                    } else {
                        enqueueSnackbar(resp.payload.message, {variant: 'error'});
                    }
                });
        }
        else {
            delete preppedTransaction.public_id;
            TransactionService.createTransaction(preppedTransaction)
                .then(resp => {
                    if (resp.success) {
                        props.reloadEntry();
                        props.handleClose();
                    } else {
                        enqueueSnackbar(resp.payload.message, {variant: 'error'});
                    }
                });
        }
    };

    const deleteTransaction = () => {
        TransactionService.deleteTransaction(transaction.public_id)
            .then(resp => {
                if (resp.success) {
                    setTransaction(emptyTransaction);
                    setEdit(false);
                    props.reloadEntry();
                    props.handleClose();
                } else {
                    enqueueSnackbar(resp.payload.message, {variant: 'error'});
                }
            });
    };

    const handleChange = e => {
        e.preventDefault();
        const attr = e.target.dataset.attribute;
        let val = e.target.value;
        let updatedTransaction = {...transaction};
        updatedTransaction[attr] = val;
        setTransaction(updatedTransaction);
    };

    useEffect(() => {
        if (props.transaction) {
            setEdit(true);
            let e = {};
            Object.keys(emptyTransaction).forEach(key => {
                e[key] = props.transaction[key];
            });
            setTransaction(e);
        } else {
            setTransaction(emptyTransaction);
            setEdit(false);
        }
    }, [props.open]);

    return (
        <div>
            <Dialog onClose={props.handleClose} open={props.open}>
                <DialogTitle>{ edit ? transaction.description : 'New Transaction' }</DialogTitle>
                <DialogContent>
                    <form id='transactionForm' onChange={handleChange} onSubmit={handleSubmit}>
                        <TextField
                          autoFocus
                          margin='dense'
                          label='Description'
                          type='text'
                          value={transaction.description}
                          inputProps={{'data-attribute': 'description'}}
                          fullWidth
                        />
                        <TextField
                          margin='dense'
                          label='Amount'
                          type='tel'
                          value={transaction.amount}
                          inputProps={{'data-attribute': 'amount'}}
                          fullWidth
                        />
                        <TextField
                          margin='dense'
                          id=''
                          type='date'
                          label='Date'
                          value={transaction.date}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{'data-attribute': 'date'}}
                          fullWidth
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" width={1}>
                        <Box alignSelf="flex-start">
                        { edit ? (
                            <Button
                                variant="contained"
                                size="small"
                                color="secondary"
                                onClick={deleteTransaction}
                                startIcon={<DeleteIcon />}
                            >
                                Delete
                            </Button>
                        ) : '' }
                        </Box>
                        <Box alignSelf="flex-end">
                            <Button
                                variant="contained"
                                size="small"
                                type='submit'
                                form='transactionForm'
                                startIcon={<SendIcon />}
                            >
                                {edit ? "Update" : "Create" }
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}
