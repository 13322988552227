import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import './App.css';
import Login from './Login/Login';
import UserService from './services/UserService';
import BudgetWrapper from './Budget/BudgetWrapper';

export default () => {
    const [loggedIn, setLoggedIn] = useState(undefined);

    useEffect(() => {
        if (loggedIn === undefined) {
            UserService.isLoggedIn()
                .then(l => {
                    setLoggedIn(l);
                });
        }
    }, [loggedIn]);

    if (loggedIn === undefined) {
        return (
            <div>loading...</div>
        )
    }

    return (
        <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
            <Router>
                <Switch>
                    <Route exact path="/">
                        { loggedIn ? <Redirect to="/budget" /> : <Redirect to='/login' /> }
                    </Route>
                    <Route path="/login">
                        { loggedIn ? <Redirect to="/budget" /> : '' }
                        <Login />
                    </Route>
                    <Route path="/budget">
                        { loggedIn ? '' : <Redirect to='/login' /> }
                        <BudgetWrapper />
                    </Route>
                </Switch>
            </Router>
        </SnackbarProvider>
    );
}
