import moment from 'moment'
import BaseAPIService from './BaseAPIService';

class PeriodService extends BaseAPIService {
    static enrichPeriod(p) {
        let start = moment(p.start_date);
        let end = moment(p.end_date);
        let now = moment();
        let length = end.diff(start, 'days') + 1;
        let progress = now.diff(start, 'days') + 1;
        progress = progress < 0 ? 0 : progress > length ? length : progress;
        let progressPercent = Math.round(progress/length * 100);

        const richPeriod = {
            id: p.id,
            startDate: start,
            endDate: end,
            name: p.name,
            length: length,
            progress: progress,
            progressPercent: progressPercent
        }
        return richPeriod;
    }

    static async getCurrentPeriod() {
        let response = await this.httpRequest('periods/now/', {auth: false});
        if (response.success) {
            response.payload = this.enrichPeriod(response.payload);
        }
        return Promise.resolve(response);
    }

    static async getPeriod(periodId) {
        const response = await this.httpRequest(`periods/${periodId}/`);
        if (response.success) {
            response.payload = this.enrichPeriod(response.payload);
        }
        return Promise.resolve(response);
    }

    static async getPeriodEntries(periodId) {
        const data = await this.httpRequest(`periods/${periodId}/entries/`);
        return Promise.resolve(data);
    }

    static async getCurrentPeriodEntries() {
        const period = await this.getCurrentPeriod();
        const data = await this.httpRequest(`periods/${period.payload.id}/entries/`);
        return Promise.resolve(data);
    }
}

export default PeriodService;
