const prod = {
    API_URL: 'https://budapi.zeot.se'
}

const dev = {
    //API_URL: 'http://10.0.10.124:5000'
    API_URL: 'https://budapi.zeot.se'
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
