import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
        MuiCardContent: {
            root: {
                padding: '16px',
                '&:last-child': {
                    'padding-bottom': '16px'
                }
            }
        }
    }
});

export default props => {
    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    )
};
