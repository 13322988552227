import BaseAPIService from './BaseAPIService';

class EntryService extends BaseAPIService {
    static async getEntryById(public_id) {
        const data = await this.httpRequest(`entries/${public_id}/`);
        return Promise.resolve(data);
    }

    static async createEntry(entry) {
        const resp = await this.httpRequest('entries/', {payload: entry});
        return Promise.resolve(resp);
    }

    static async deleteEntry(public_id) {
        const data = await this.httpRequest(`entries/${public_id}/`, {method: 'DELETE'});
        return Promise.resolve(data);
    }

    static async editEntry(entry) {
        const data = await this.httpRequest(`entries/${entry.public_id}/`, {method: `PUT`, payload: entry});
        return Promise.resolve(data);
    }
}

export default EntryService;
