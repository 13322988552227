import React, { useState, useEffect } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Collapse,
    Button,
    Box,
    Grid,
}  from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import './Entry.scss';
import Transaction from './Transaction/Transaction';
import TransactionForm from './TransactionForm/TransactionForm';
import EntryService from '../../../services/EntryService';

const useStyles = makeStyles(theme => ({
    entryComplete: {
        backgroundColor: '#dddddd'
    },
    entryOverdrawn: {
        backgroundColor: '#ff684c'
    },
    amount: {
        color: 'white',
        padding: '4px',
        borderRadius: '10px'
    },
    incoming: {
        backgroundColor: 'green',
    },
    outgoing: {
        backgroundColor: 'red',
    },
    tinyText: {
        fontSize: '0.7em'
    },
}));

export default (props) => {
    const [ collapsed, setCollapsed ] = useState(false);
    const [ entryStatus, setEntryStatus ] = useState('');
    const [ transactionFormOpen, setTransactionFormOpen ] = useState(false);
    const [ transactionEdit, setTransactionEdit ] = useState(false);


    const toggleExpand = () => setCollapsed(!collapsed);
    const classes = useStyles();

    const deleteEntry = () => {
        console.log('delete entry');
        console.log(props.entry.public_id);
        EntryService.deleteEntry(props.entry.public_id)
            .then(resp => {
                reloadEntry();
            });
    };

    const editEntry = () => {
        props.editEntry(props.entry);
    };

    const reloadEntry = () => {
        props.reloadEntries();
    };

    const addTransaction = () => {
        setTransactionFormOpen(true);
    };

    const editTransaction = (transaction) => {
        setTransactionFormOpen(true);
        setTransactionEdit(transaction);
        console.log(transactionEdit);
    };

    const handleAddTransactionClose = () => {
        setTransactionEdit(false);
        setTransactionFormOpen(false)
    };

    useEffect(() => {
        const { amount, current_amount } = props.entry;
        if (amount === current_amount) {
            setEntryStatus('completed');
        }
        else if (current_amount > amount) {
            setEntryStatus('overdrawn');
        }
        else {
            setEntryStatus('');
        }
    }, [props.entry]);

    let amountClasses = [ classes.amount, props.entry.incoming ? classes.incoming : classes.outgoing];

    return (
        <>
            <Card className="entry">
                <CardHeader
                    title={ props.entry.name }
                    subheader={
                            <Grid container>
                                <Grid item xs={4}><span className={ amountClasses.join(' ') }>{ props.entry.amount.toFixed(2) }</span></Grid>
                                <Grid item xs={5}>{(props.entry.amount - props.entry.current_amount).toFixed(2)}<span className={ classes.tinyText }> left</span></Grid>
                                <Grid item xs={3}>{ moment(props.entry.planned_date).format('DD MMM')}</Grid>
                            </Grid> }
                    className={ entryStatus === 'completed'
                                  ? classes.entryComplete
                                  : entryStatus === 'overdrawn'
                                      ? classes.entryOverdrawn
                                      : '' }
                    action={<>
                            <IconButton onClick={ addTransaction }>
                                <AddIcon fontSize='large' />
                            </IconButton>
                            <IconButton  onClick={toggleExpand}>
                                {collapsed ? <ExpandMoreIcon fontSize='large'/> : <ExpandLessIcon fontSize='large'/> }
                            </IconButton>
                            </>}
                />
                <Collapse
                    in={ collapsed }
                    timeout="auto"
                    unmountOnExit
                >
                    <CardContent >
                        {props.entry.transactions.map(t => (
                            <Transaction
                                transaction={ t }
                                editTransaction={ editTransaction }
                                key={ t.public_id }
                            />
                        ))}
                    </CardContent>
                    <CardActions>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            className={ classes.entryActionRow }
                            width={1}
                        >
                            <Button
                                variant="contained"
                                size="small"
                                color="secondary"
                                onClick={deleteEntry}
                                startIcon={<DeleteIcon />}
                            >
                                Delete
                            </Button>
                            <Box alignSelf="flex-end">
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={editEntry}
                                    startIcon={<EditIcon />}
                                >
                                    Edit
                                </Button>
                            </Box>
                        </Box>
                    </CardActions>
                </Collapse>
            </Card>
            <TransactionForm
                open={ transactionFormOpen }
                handleClose={ handleAddTransactionClose }
                reloadEntry={ reloadEntry }
                transaction={ transactionEdit }
                entry={ props.entry }
            />
        </>
    );
}
