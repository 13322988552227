import React, { useState, useEffect, } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import './EntryList.scss';
import Entry from './Entry/Entry';
import EntryForm from './EntryForm/EntryForm';
import PeriodService from '../../services/PeriodService';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: 0,
        right: 0
    },
    entryList: {
        overflow: 'auto',
        height: '100%'
    }
}));


const EntryList = props => {
    const [ entries, setEntries ] = useState([]);
    const [ entryEdit, setEntryEdit ] = useState(false);
    const [ entryFormOpen, setEntryFormOpen ] = useState(false);
    const [ period, setPeriod ] = useState({});
    const classes = useStyles();
    const { periodId } = props;
    const { enqueueSnackbar } = useSnackbar();

    const addEntry = () => {
        setEntryEdit(false);
        setEntryFormOpen(true);
    };

    const sortEntries = e => {
        let entriesSorted = [...e];
        entriesSorted.sort((a, b) => {
            const a_prog = a.amount - a.current_amount;
            const b_prog = b.amount - b.current_amount;
            const a_d = moment(a.planned_date).unix();
            const b_d = moment(b.planned_date).unix();
            if (a_prog < 0 && b_prog >= 0) {
                return -1;
            } else if (a_prog >= 0 && b_prog < 0) {
                return 1;
            } else {
                if (a_prog <= 0 && b_prog > 0) {
                    return 1;
                } else if (a_prog > 0 && b_prog <= 0) {
                    return -1;
                } else {
                    if (a_d === b_d) {
                        return 0;
                    } else {
                        return a_d - b_d;
                    }
                }
            }
        });
        return entriesSorted;
    };

    const handleAddEntryClose = () => {
        setEntryEdit(false);
        setEntryFormOpen(false)
    };

    const fetchEntries = () => {
        (periodId === undefined ? PeriodService.getCurrentPeriodEntries() : PeriodService.getPeriodEntries(periodId))
            .then(resp => {
                if (resp.success) {
                    setEntries(sortEntries(resp.payload.data));
                } else {
                    enqueueSnackbar(resp.payload.msg, {variant: 'error'});
                }
            });
    }
    
    const fetchPeriod = () => {
        (periodId === undefined ? PeriodService.getCurrentPeriod() : PeriodService.getPeriod(periodId))
            .then(resp => {
                if (resp.success) {
                    setPeriod(resp.payload);
                } else {
                    enqueueSnackbar(resp.payload.msg, {variant: 'error'});
                }
            });
    }

    const editEntry = (entry) => {
        setEntryEdit(entry);
        setEntryFormOpen(true);
    };

    useEffect(() => {
        fetchEntries();
        fetchPeriod();
    }, [periodId]);

    return (
        <Box flex={1}>
            <div className={classes.entryList}>
            {entries.map(entry => (
                <Entry entry={entry} key={entry.public_id} editEntry={editEntry} reloadEntries={ fetchEntries }/>
            ))}
            </div>
            <EntryForm open={ entryFormOpen }
                       handleClose={ handleAddEntryClose }
                       reloadEntries={ fetchEntries }
                       entry={ entryEdit }
                       period={ period } />
            <Fab aria-label='Add' className={classes.fab} color='primary' onClick={addEntry}>
                <AddIcon />
            </Fab>
        </Box>
    );
}

export default EntryList;
