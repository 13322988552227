import Cookies from 'js-cookie';
import { config } from '../utils/constants';
import { getTokenExpire } from '../utils/jwt';

class UserService {
    static async loginUser(username, password) {
        const url = `${config.API_URL}/auth/login`;
        const headers = {
            'Content-Type': 'application/json'
        };
        const body = JSON.stringify({
            email: username,
            password: password
        });

        const opts = {
            method: 'POST',
            headers: headers,
            body: body
        };

        let loginResponse = await fetch(url, opts)
            .then(resp => {
                return resp.json()
            });

        if (loginResponse.status === 'fail') {
            return Promise.resolve({success: false, error: `Login failed: ${loginResponse.msg}`});
        }
        else {
            const {authorization: {access, refresh}} = loginResponse;
            Cookies.set('accessToken', access, { expires: 365 });
            Cookies.set('refreshToken', refresh, { expires: 365 });
            return Promise.resolve({success: true, data: 'Login successful'});
        }
    }

    static async getAccessToken() {
        const updateSuccessful = await this.updateAccessToken();

        if (updateSuccessful) {
            const accessToken = Cookies.get('accessToken');
            return Promise.resolve(accessToken);
        }

        return Promise.resolve();
    }

    static async isLoggedIn() {
        const token = await this.getAccessToken();
        return Promise.resolve(token !== undefined);
    }


    static async refreshToken() {
        const url = `${config.API_URL}/auth/refresh`;

        const refreshToken = Cookies.get('refreshToken');

        if (refreshToken === '') {
            return Promise.resolve('Refreshing token failed: No refresh token set');
        }

        const headers = {
            Authorization: `Bearer ${refreshToken}`
        };

        const opts = {
            method: 'POST',
            headers: headers
        };

        let resp = await fetch(url, opts);
        if (! resp.ok) {
            console.log(resp);
        }
        let data = await resp.json();
        Cookies.set('accessToken', data.authorization.access);
    }

    static async updateAccessToken() {
        const accessToken = Cookies.get('accessToken');
        const refreshToken = Cookies.get('refreshToken');

        if (! accessToken || ! refreshToken) {
            return Promise
        }
        
        const accessDuration = getTokenExpire(accessToken);
        if (accessDuration < 30) {
            const refreshDuration = getTokenExpire(refreshToken);
            if (refreshDuration > 10) {
                await this.refreshToken();
            }
            else {
                console.log('refresh token expired. remove tokens from user');
                return Promise.resolve(false);
            }
        }
        return Promise.resolve(true);
    }
}

export default UserService;
