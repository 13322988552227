import moment from 'moment';

export function jwtDecode(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

export function getTokenExpire(token) {
    const body = jwtDecode(token);
    const expire = moment.unix(body.exp);
    const now = moment();
    const secondsToExpire = expire.diff(now, 'seconds');
    return secondsToExpire < 0 ? 0 : secondsToExpire;
}
