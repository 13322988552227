import React from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

import UserService from '../services/UserService';

export default props => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const handleSubmit = (e) => {
        e.preventDefault()

        const {email, password} = e.target.elements;

        UserService.loginUser(email.value, password.value)
            .then((resp) => {
                if (resp.success) {
                    window.location.replace("/budget");
                }
                else {
                    console.log(resp);
                    enqueueSnackbar(resp.error, {variant: 'error'});
                }
            });
    }

    return (
        <div>
            <Dialog open={true}>
                <DialogTitle>Sign in</DialogTitle>
                <DialogContent>
                    <form id="loginForm" onSubmit={handleSubmit}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="email"
                          label="Email address"
                          type="email"
                          fullWidth
                        />
                        <TextField
                          margin="dense"
                          id="password"
                          label="Password"
                          type="password"
                          fullWidth
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" type="submit" form="loginForm">
                        Login
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
